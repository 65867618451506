import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
export default class Service {
  axios: AxiosInstance;

  constructor(serviceUrl: string, authenticate: boolean = true) {
    this.axios = Axios.create({
      baseURL: serviceUrl,
    });
    if (authenticate) {
      this.axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          let token = localStorage.getItem("token");
          if (token) {
            token = JSON.parse(token).token;
            // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error: AxiosError) => Promise.reject(error)
      );
    }
  }
}
