import React from "react";
import { useEffect, useState } from "react";
import {
  authenticateAndSaveUser,
  isAuthenticated,
  isMercuryGateUser,
  redirectToLogin,
  redirectToLogout,
} from "./utils/authUtils";
import { useAuthQueryParams } from "./hooks/useQueryParams";
import LoadingSpinner from "./components/LoadingSpinner";
import MGTokenForm from "./features/mgTokenForm";
import APICallLog from "./features/apiCallLogs";
import AppHeader from "./components/appHeader/AppHeader";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const {
    queryParams: { email, token },
  } = useAuthQueryParams();

  useEffect(() => {
    const authenticateUser = async () => {
      // if we have an email and token we always need to go revalidate
      // a new token could have been generated when core-web routes to our site
      if (email && token) {
        try {
          await authenticateAndSaveUser(token, email);
          setIsLoading(false);
          return;
        } catch (error) {
          redirectToLogout();
        }
      }

      if (isAuthenticated()) {
        setIsLoading(false);
        return;
      }
      // when we logout core-web will redirect here without the token and email
      // and expects us to redirect back to the proper login url
      redirectToLogin();
    };
    authenticateUser();
  }, [email, token]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AppHeader>
        {isMercuryGateUser() && (
          <>
            <NavLink
              to="/tokenGeneration"
              className="navItem"
              activeClassName="active"
            >
              Token Generation
            </NavLink>
            <NavLink
              to="/apiCallLog"
              className="navItem"
              activeClassName="active"
            >
              API Call Log
            </NavLink>
          </>
        )}
      </AppHeader>
      <Switch>
        <Route path="/tokenGeneration" component={MGTokenForm}></Route>
        <Route path="/apiCallLog" component={APICallLog}></Route>
        <Redirect from="/" to="/tokenGeneration" />
      </Switch>
    </>
  );
}

export default App;
