import React, { useEffect, useRef } from "react";
import { Snackbar } from "shamrock-clover-ui/dist/clover/components/Snackbar/Snackbar";
import { useState } from "react";
import { MultilineTextInput } from "shamrock-clover-ui/dist/clover/components/MultilineTextInput/MultilineTextInput";
import { Icon as CloverIcon } from "shamrock-clover-ui/dist/clover/components/Icon/Icon";
import Grid from "@material-ui/core/Grid";
import { getTokenByUsername } from "./GetTokenHttp";
import { insertMercuryGateToken, emailMercuryGateToken } from "./PostTokenHttp";
import { Spinner } from "shamrock-clover-ui/dist/clover/components/Spinner/Spinner";
import { validateEmail } from "@shamrock-core/common/utils";
import Mailcheck from "mailcheck";
import { Button } from "shamrock-clover-ui/dist/clover/components/Button/Button";

import {
  StyledHeader,
  StyledText,
  StyledTextInput,
  StyledButton,
  GridItem,
  TokenCard,
  SuggestedEmailText,
  CopyTokenFlex,
  CopyTokenText,
  StyledInputGrid,
  StyledTokenGrid,
  SpinnerContainer,
  HelperText,
  StyledHr,
  ButtonContainer,
} from "./mgTokenFormStyles";
import DeleteUserModal from "./DeleteUserModal";

const MGTokenForm = () => {
  //values
  const [clientCodeValue, setClientCodeValue] = useState<string>("");
  const [mgPasswordValue, setMgPasswordValue] = useState<string>("");
  const [mgUsernameValue, setMgUsernameValue] = useState<string>("");
  const [tokenValue, setTokenValue] = useState<string>("");
  const [emailValuesString, setEmailValuesString] = useState<string>("");
  const [emailValues, setEmailValues] = useState<string[]>([]);

  //misc
  const [tokenExists, setTokenExists] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  //displays
  const [displayInputs, toggleDisplayInputs] = useState<boolean>(false);
  const [displayToken, toggleDisplayToken] = useState<boolean>(false);
  const [displayDeleteModal, toggleDeleteModal] = useState<boolean>(false);

  //input validation
  const [mgUsernameErrorCheck, setMgUsernameErrorCheck] =
    useState<boolean>(false);
  const [inputsErrorCheck, setInputsErrorCheck] = useState<boolean>(false);
  const [emailErrorCheck, setEmailErrorCheck] = useState<boolean>(false);

  const [mgUsernameError, setMGUsernameError] = useState<string>(
    "Username must be at least 3 characters"
  );
  const [clientCodeError, setClientCodeError] = useState<string>(
    "Code must be at least 3 digits"
  );
  const [mgPasswordError, setMGPasswordError] = useState<string>(
    "Password must be at least 3 characters"
  );
  const [emailTokenError, setEmailTokenError] =
    useState<string>("Invalid email");
  const [suggestedEmail, setSuggestedEmail] = useState<string>("");

  //snackbar
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const emailTextInput = useRef(null);

  // onClick handler function for the continue button
  const onContinueClick = async () => {
    setMgUsernameErrorCheck(true);
    if (mgUsernameError || !mgUsernameValue) {
      return;
    }

    toggleIsLoading(true);
    try {
      let result = await getTokenByUsername(mgUsernameValue);
      result = await result;
      if (result.token) {
        setTokenExists(true);
        setClientCodeValue(result.clientCode);
        setTokenValue(result.token);
        toggleDisplayToken(true);
      }
    } catch (error) {
      console.log("error:" + error);
      clearVariables();
    }
    toggleIsLoading(false);
    toggleDisplayInputs(true);
    setDisabled(false);
  };

  // onClick handler function for the copy button
  const onCopyClick = async (token: string) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(token);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };

  // onClick handler function for the build token button
  const onBuildClick = async () => {
    setInputsErrorCheck(true);
    if (
      clientCodeError ||
      mgPasswordError ||
      !clientCodeValue ||
      !mgPasswordValue
    ) {
      return;
    }

    setDisabled(true);
    toggleIsLoading(true);
    const body = {
      clientCode: clientCodeValue,
      mgPassword: mgPasswordValue,
      mgUsername: mgUsernameValue,
    };
    try {
      let result = await insertMercuryGateToken(body);
      result = await result;
      setTokenValue(result.data.token);
      toggleDisplayToken(true);

      setSnackbarMessage("Token successfully created.");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("There was an error generating the token. Try again.");
      setSnackbarSuccess(false);

      setDisabled(false);
      console.log("error:" + error);
    }
    setIsSnackbarOpen(true);
    toggleIsLoading(false);
  };

  // onClick handler function for the email token button
  const onEmailTokenClick = async () => {
    setEmailErrorCheck(true);
    if (emailTokenError) {
      return;
    }

    toggleIsLoading(true);

    const body = {
      userEmails: emailValues,
      token: tokenValue,
      clientCode: clientCodeValue,
      mgUsername: mgUsernameValue,
    };

    try {
      let result = await emailMercuryGateToken(body);
      result = await result;
      console.log(result);

      setSnackbarMessage("Token successfully emailed.");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Error sending email. Please try again.");
      setSnackbarSuccess(false);
      console.log("error:" + error);
    }
    console.log("message: " + snackbarMessage);
    console.log("succes: " + snackbarSuccess);

    toggleIsLoading(false);
    setIsSnackbarOpen(true);
  };

  // onClick handler function for the delete user button
  const onDeleteUser = async () => {
    toggleDeleteModal(true);
  };

  // Clear state vars
  const clearVariables = async () => {
    setTokenExists(false);
    setClientCodeValue("");
    setMgPasswordValue("");
    setTokenValue("");
    toggleDeleteModal(false);
    toggleDisplayToken(false);
    toggleIsLoading(false);
    toggleDisplayInputs(true);
    setDisabled(false);
  };

  useEffect(() => {
    const emailList = emailValuesString.replace(/ /g, "").split(";");
    setEmailValuesString(emailValuesString);
    setEmailValues(emailList);
    setEmailTokenError("");

    emailList.map((email) => {
      if (email && !validateEmail(email)) {
        setEmailTokenError("Invalid Email: " + email);
      }
      return email;
    });

    const currentEmail = emailList[emailList.length - 1];

    const domains = ["ryanrts.com", "shamrocktradingcorp.com", "gmail.com"];
    const suggestedEmail = Mailcheck.run({
      email: currentEmail,
      domains: domains,
    })?.full;
    if (suggestedEmail && currentEmail !== suggestedEmail) {
      setSuggestedEmail(suggestedEmail);
      return;
    }
    setSuggestedEmail("");
  }, [emailValuesString]);

  return (
    <Grid container>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : null}
      <StyledInputGrid container item xs={12} sm={6} md={8}>
        <GridItem item sm={12}>
          <StyledHeader>Token generation</StyledHeader>
          <StyledText>
            1. Please enter your Mercury Gate username to continue.
          </StyledText>
          <StyledTextInput
            label="Mercury Gate Username"
            value={mgUsernameValue}
            variant="filled"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              setMgUsernameValue(value);

              toggleDisplayInputs(false);
              toggleDisplayToken(false);

              if (value.length < 3) {
                setMGUsernameError("Username must be at least 3 characters");
                return;
              }
              setMGUsernameError("");
            }}
            error={mgUsernameErrorCheck ? mgUsernameError : ""}
          />
          <StyledButton width="100px" onClick={() => onContinueClick()}>
            CONTINUE
          </StyledButton>
          <StyledHr />
        </GridItem>
        {displayInputs ? (
          <GridItem item sm={12}>
            {tokenExists ? (
              <>
                <StyledText>
                  2. Done. We found a token associated with this username.
                </StyledText>
              </>
            ) : (
              <>
                <StyledText>
                  2. Please provide the following information to generate a
                  token.
                </StyledText>
                <StyledTextInput
                  helperText="Client receiving the token"
                  label="Client Code"
                  value={clientCodeValue}
                  variant="filled"
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    setClientCodeValue(value);

                    if (value.length < 3) {
                      setClientCodeError("Code must be at least 3 digits");
                      return;
                    }
                    setClientCodeError("");
                  }}
                  error={inputsErrorCheck ? clientCodeError : ""}
                  disabled={disabled}
                />
                <StyledTextInput
                  label="Mercury Gate Password"
                  value={mgPasswordValue}
                  inputType="password"
                  variant="filled"
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    setMgPasswordValue(value);

                    if (value.length < 3) {
                      setMGPasswordError(
                        "Password must be at least 3 characters"
                      );
                      return;
                    }
                    setMGPasswordError("");
                  }}
                  error={inputsErrorCheck ? mgPasswordError : ""}
                  disabled={disabled}
                />
                <StyledButton width="130px" onClick={() => onBuildClick()}>
                  BUILD TOKEN
                </StyledButton>
              </>
            )}
          </GridItem>
        ) : (
          <></>
        )}
      </StyledInputGrid>
      <StyledTokenGrid item xs={12} sm={6} md={4}>
        {displayToken ? (
          <TokenCard>
            <Grid container>
              <Grid item xs={12}>
                {tokenExists ? (
                  <h2>Existing Token</h2>
                ) : (
                  <h2>Generated Token</h2>
                )}
              </Grid>
              <Grid item xs={12}>
                <p overflow-wrap="anywhere">Client code: {clientCodeValue}</p>
              </Grid>
              <Grid item xs={12}>
                <MultilineTextInput
                  disabled
                  variant="filled"
                  value={tokenValue ?? ""}
                />
              </Grid>
              <Grid item xs={12}>
                <CopyTokenFlex onClick={() => onCopyClick(tokenValue || "")}>
                  <CloverIcon
                    icon="clipboard"
                    size="16"
                    onClick={() => {}}
                  ></CloverIcon>
                  <CopyTokenText>
                    {isCopied ? "COPIED!" : "COPY TOKEN"}
                  </CopyTokenText>
                </CopyTokenFlex>
              </Grid>
              <Grid item xs={12}>
                <MultilineTextInput
                  label="Email"
                  ref={emailTextInput}
                  value={emailValuesString}
                  variant="filled"
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    setEmailValuesString(value);
                  }}
                  error={emailErrorCheck ? emailTokenError : ""}
                />
                <HelperText>
                  {emailErrorCheck && emailTokenError ? (
                    <></>
                  ) : (
                    <>
                      {suggestedEmail ? (
                        <>
                          Did you mean
                          <SuggestedEmailText
                            onClick={() => {
                              setEmailValuesString(
                                emailValuesString.replace(
                                  emailValues[emailValues.length - 1],
                                  suggestedEmail
                                )
                              );
                            }}
                            padding-right="1px"
                            cursor="pointer"
                          >
                            {suggestedEmail}
                          </SuggestedEmailText>
                          ?
                        </>
                      ) : (
                        <>Seperate emails with a semi-colon</>
                      )}
                    </>
                  )}
                </HelperText>
              </Grid>
              <Grid item xs={12}>
                <ButtonContainer>
                  <Button
                    color="red"
                    width="132px"
                    onClick={() => onDeleteUser()}
                  >
                    DELETE USER
                  </Button>
                  <Button width="130px" onClick={() => onEmailTokenClick()}>
                    EMAIL TOKEN
                  </Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </TokenCard>
        ) : (
          <></>
        )}
      </StyledTokenGrid>
      <DeleteUserModal
        mgUsername={mgUsernameValue}
        isOpen={displayDeleteModal}
        setIsOpen={toggleDeleteModal}
        clearVariables={clearVariables}
        setIsSnackbarOpen={setIsSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSuccess={setSnackbarSuccess}
      />
      <Snackbar
        duration={4000}
        open={isSnackbarOpen}
        onClose={() => {
          setIsSnackbarOpen(false);
        }}
        message={snackbarMessage}
        variant={snackbarSuccess ? "success" : "error"}
      />
    </Grid>
  );
};

export default MGTokenForm;
