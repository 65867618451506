import { get } from "../../utils/httpUtils";
import { QueryInputSelected, APICallLogApiResult } from "./apiCallLogTypes";

const parseQuery = (query: QueryInputSelected) => {
  let buildQuery: string = "?";

  for (const key in query) {
    if (query[key as keyof QueryInputSelected] !== "") {
      console.log("Object.keys(query).length :>> ", Object.keys(query).length);
      buildQuery += `${key}=${query[key as keyof QueryInputSelected]}&`;
    }
  }
  return buildQuery;
};

export const getLogs = async (query: QueryInputSelected) => {
  const queryString: string = parseQuery(query);
  console.log("queryString :>> ", queryString);
  const data = await get<APICallLogApiResult>(`mg/logs`)(queryString);
  console.log("data :>> ", data);
  return data;
};

export const getLogQueryAutofill = async ([query, value]: string[]) => {
  const queryString = `?${query}=${value}`;
  try {
    const data = await get(`mg/logs/autofill`)(queryString);
    return data;
  } catch (error) {
    console.log("error :>> ", error);
    return { error: "not found" };
  }
};
