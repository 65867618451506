import { getUserLocalStorage } from "./authUtils";

declare const firebase: any;

const cache: CachedItem[] = [];
let isLoaded = false;
let errorLoadingScript = false;
let userLatitude = 0;
let userLongitude = 0;
let userDataSet = false;

export enum FireBaseFunc {
  LOG_FIREBASE_SCREEN_NAME = "logFirebaseScreenName",
  LOG_FIREBASE_USER = "logFirebaseUser",
  LOG_PAGE_VIEW = "logPageView",
  LOG_FIREBASE_EVENT = "logFirebaseEvent",
}

interface CachedItem {
  firebaseFunc: FireBaseFunc;
  params: Array<any>;
}

interface FirebaseConfigProps {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.src = src;
    script.addEventListener("load", (event) => {
      resolve();
    });
    script.addEventListener("error", (event) => {
      reject();
    });
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  });
};

export enum FirebaseParameters {
  ORDER_NUMBER = "order_number",
  QUOTE_AMOUNT = "quote_amount",
  CARRIER_NAME = "carrier_name",
  MCLEOD_ID = "mcleod_id",
  USER_ID = "UserID",
  USER_TYPE = "userType",
}

export enum FirebasePages {
  LIST = "list",
  DETAILS = "details",
  DAT_RATEVIEW = "DAT Rateview",
  RTS_HISTORY = "RTS History",
  DAT_RATECAST = "DAT Ratecast",
  RATE_SONAR_SCORES = "Rate Sonar scores",
  YOUR_RATES = "Your Rates",
  EASYTRACKFULL = "EasyTrackFull",
  EASYTRACK = "EasyTrack",
}

export enum FirebaseModules {
  QUOTING_ADMIN = "QuotingAdmin",
  RATE_PRO = "Rate Pro",
  EASYTRACKFULL = "EasyTrackFull",
  EASYTRACK = "EasyTrack",
}

export enum FirebaseEvents {
  DETAIL_CLICK = "detail_click",
  ACCEPT = "accept",
  COUNTER = "counter",
  DECLINE = "decline",
  COMPLETE = "complete",
  SEARCH = "search",
  CLICK = "click",
  LOGIN = "login",
}

export const logFirebaseUser = (
  userId: string | undefined,
  userType?: string | undefined
) => {
  if (cacheEvent(FireBaseFunc.LOG_FIREBASE_USER, [userId, userType])) {
    return;
  }
  firebase
    .analytics()
    .setUserProperties({ [FirebaseParameters.USER_ID]: userId });
  firebase.analytics().setUserId(userId);

  if (userType) {
    firebase
      .analytics()
      .setUserProperties({ [FirebaseParameters.USER_TYPE]: userType });
  }

  userDataSet = true;
};

export const logFirebaseEvent = (
  event: FirebaseEvents,
  module: FirebaseModules,
  page: FirebasePages,
  customParams: any
) => {
  if (
    cacheEvent(FireBaseFunc.LOG_FIREBASE_EVENT, [
      event,
      module,
      page,
      customParams,
    ])
  ) {
    return;
  }
  if (!userDataSet) {
    const user = getUserLocalStorage();
    logFirebaseUser(user?._id, user?.user_type);
  }

  let mainParams = {
    module: module,
    page: page,
    userLatitude,
    userLongitude,
  };

  let allParams = Object.assign({}, customParams, mainParams);

  firebase.analytics().logEvent(event, allParams);
};

export const init = async (firebaseConfig: FirebaseConfigProps) => {
  await loadScript("https://www.gstatic.com/firebasejs/7.16.0/firebase-app.js");
  await loadScript(
    "https://www.gstatic.com/firebasejs/7.16.0/firebase-analytics.js"
  );

  isLoaded = true;
  firebase.initializeApp(firebaseConfig);
};

const cacheEvent = (
  firebaseFunc: FireBaseFunc,
  params: Array<any>
): boolean => {
  if (isLoaded === false && errorLoadingScript === false) {
    cache.push({ firebaseFunc, params });
    return true;
  }
  // if we fail to load script we do not want
  // to cache or try to log
  if (errorLoadingScript) return true;
  return false;
};
