import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";

export const useQueryParams = <T extends {}>() => {
  const { search } = useLocation();
  const { push } = useHistory();

  const addQueryParams = (paramsToAdd: T) => {
    const query = queryString.parse(window.location.search);

    push({
      search: queryString.stringify({
        ...query,
        ...paramsToAdd,
      }),
    });
  };

  const clearQueryParams = () => {
    push({
      search: "",
    });
  };

  const setQueryParams = (paramsToAdd: T) => {
    push({
      search: queryString.stringify({
        ...paramsToAdd,
      }),
    });
  };

  return {
    addQueryParams,
    clearQueryParams,
    setQueryParams,
    queryParams: queryString.parse(search) as T,
  };
};

export const useAuthQueryParams = () =>
  useQueryParams<{
    token: string;
    email: string;
  }>();
