import { AuthService } from "../../services";
import Service from "../Service";
import to from "await-to-js";
import { CURRENT_USER } from "../../utils/constants";

// const Config = require("Config");
const SHIPPER_PERMISSION = "ryantrans.Shipper";

export const INVALID_PERMISSIONS_MSG = "Invalid Permissions";

export interface User {
  company: string;
  createdOn: string;
  crmId: string;
  device_ids: string[];
  email: string;
  factoring_status: string;
  phone?: string;
  firstname: string;
  lastname: string;
  mcleod_customer_id: string[];
  note: string;
  permissions: { name: string; status: string }[];
  pin: string;
  roles: string[];
  status: string;
  user_type: string;
  _id: string;
}

class UserService extends Service {
  authService: AuthService;

  constructor() {
    super("");
    // super(Config.sentinelServiceUrl);
    this.authService = new AuthService();
  }

  async getUser() {
    const token = this.authService.getToken;
    if (!token) {
      throw Error("Not Logged In");
    }

    const [error, response] = await to(
      this.axios.get<User>("user/user/" + token)
    );
    if (error || !response) {
      throw Error("Not Logged In");
    }
    const { status, data: user } = response;
    if (status !== 200) {
      throw Error("Invalid status response");
    }

    localStorage.setItem(CURRENT_USER, JSON.stringify(user));

    return user;
  }

  async isUserValid() {
    const [error, user] = await to(this.getUser());
    if (error || !user) {
      throw error;
    }

    const isValid = user.permissions.some(
      (x: { name: string }) => x.name === SHIPPER_PERMISSION
    );
    return isValid;
  }

  static getCurrentUser = () => {
    const currentUser = localStorage.getItem(CURRENT_USER);

    return currentUser ? (JSON.parse(currentUser) as User) : undefined;
  };

  static getUserEmail = (): string => {
    const currentUser = localStorage.getItem(CURRENT_USER);
    return currentUser ? JSON.parse(currentUser).email : "";
  };
}

export default UserService;
