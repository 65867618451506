import React from "react";
import { Button } from "shamrock-clover-ui/dist/clover/components/Button/Button";
import { styled } from "shamrock-clover-ui/dist/clover/Theme/Theme";
import { deleteMercuryGateUser } from "./PostTokenHttp";
import { Spinner } from "shamrock-clover-ui/dist/clover/components/Spinner/Spinner";
import { SpinnerContainer } from "./mgTokenFormStyles";

export const BackDrop = styled.div`
  align-items: center;
  background-color: #00000080;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  visibility: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 99999;
`;

export const StyledModal = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
  width: 500px;
  color: #333333;
  opacity: 1;
  box-sizing: border-box;
  padding: 64px;
`;

export const ModalTitle = styled.div`
  color: #333333;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  margin-bottom: 32px;
`;

export const ModalText = styled.div`
  color: #333333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  mgUsername: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  clearVariables: Function;
  setIsSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteUserModal: React.FunctionComponent<Props> = ({
  mgUsername,
  isOpen,
  setIsOpen,
  clearVariables,
  setIsSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSuccess,
}) => {
  const [isLoading, toggleIsLoading] = React.useState<boolean>(false);

  const handleDelete = async () => {
    toggleIsLoading(true);
    try {
      await deleteMercuryGateUser(mgUsername);
      setIsSnackbarOpen(true);
      setSnackbarMessage("User successfully deleted.");
      setSnackbarSuccess(true);
    } catch (error) {
      setIsSnackbarOpen(true);
      setSnackbarMessage("Error deleting user.");
      setSnackbarSuccess(false);
    }
    toggleIsLoading(false);
    clearVariables();
  };

  const continueEditing = () => {
    setIsOpen(false);
  };

  if (!isOpen || !mgUsername) return <></>;
  return (
    <BackDrop>
      <StyledModal>
        <ModalTitle>Delete this user?</ModalTitle>
        <ModalText>{mgUsername}</ModalText>
        <ButtonContainer>
          <Button buttonStyle="outlined" onClick={continueEditing}>
            CANCEL
          </Button>
          <Button buttonStyle="solid" onClick={handleDelete}>
            DELETE
          </Button>
        </ButtonContainer>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <></>
        )}
      </StyledModal>
    </BackDrop>
  );
};

export default DeleteUserModal;
