import { getAuthToken, redirectToLogin } from "./authUtils";

const BASE_URL = process.env.REACT_APP_SERVICE_BASE_URL;

const defaultOptions = {
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
    "Content-Type": "application/json",
  },
};

const handleResponse = async (response: Response) => {
  if (response.status === 401) {
    redirectToLogin();
    return;
  }

  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
};

export const get =
  <T extends unknown, Q extends unknown = {}>(url: string) =>
  async (query?: Q): Promise<T> => {
    const formattedUrl = query
      ? `${BASE_URL}/${url}${query}`
      : `${BASE_URL}/${url}`;

    return handleResponse(await fetch(formattedUrl, defaultOptions));
  };

export const post =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body: B): Promise<T> => {
    return handleResponse(
      await fetch(`${BASE_URL}/${url}`, {
        ...defaultOptions,
        method: "POST",
        body: JSON.stringify(body),
      })
    );
  };

export const put =
  <T extends unknown, B extends unknown>(url: string) =>
  async (body: B): Promise<T> => {
    return handleResponse(
      await fetch(`${BASE_URL}/${url}`, {
        ...defaultOptions,
        method: "PUT",
        body: JSON.stringify(body),
      })
    );
  };
