import { TextInput } from "shamrock-clover-ui/dist/clover/components/TextInput/TextInput";
import Grid from "@material-ui/core/Grid";
import { styled } from "shamrock-clover-ui/dist/clover/Theme/Theme";
import DatePickerField from "../../components/datePickerField";
import { Icon } from "shamrock-clover-ui/dist/clover/components/Icon/Icon";

export const StyledHeader = styled.p`
  font-size: 36px;
  margin-bottom: 0;
`;

export const StyledTextInput = styled(TextInput)``;

export const StyledMenu = styled.div`
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 24%);
  max-height: 250px;
  overflow: auto;
  width: 250px;
`;

export const Container = styled.div`
  background-color: white;
  height: 91vh;
  width: 100vw;
`;

export const GridContainer = styled(Grid)`
  padding: 0px 25px 0px 25px;
`;

export const StyledGrid = styled(Grid)`
  overflow-x: auto;
`;

export const GridItem = styled(Grid)`
  width: inherit;
  padding: 15px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const PageNavFlex = styled(Flex)`
  align-items: center;
  padding: 7px;
  float: right;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 50%;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 1;
`;

export const DatePickerStyled = styled(DatePickerField)`
  font-family: proxima-nova, arial, sans-serif;
  font-size: 16px !important;
  width: 100%;
  > div > input {
    padding: 15px 10px 10px 10px;
    color: #666666;
    height: 30px;
  }
  > div:before {
    border-bottom: 1px solid #cccccc !important;
  }
  color: blue;
`;

export const StyledTable = styled.table`
  margin-top: 75px;
  min-width: 1200px;
  overflow: scroll;
  display: block;
  height: 50vh;
  border-collapse: collapse;
  width: 100%;
`;

export const TableHead = styled.thead`
  display: table;
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
`;

export const TableBody = styled.tbody`
  display: inline-table;
  table-layout: fixed;
  width: 100%;
`;

export const StyledRow = styled.tr`
  padding: 5px;
  border-bottom: 1px solid #0000001f;
`;

export const StyledCell = styled.td`
  padding: 13px;
`;
export const CallTypeCell = styled(StyledCell)`
  width: 11%;
`;

export const ClientCodeCell = styled(StyledCell)`
  width: 12%;
`;

export const UsernameCell = styled(StyledCell)`
  width: 13%;
`;

export const DateCell = styled(StyledCell)`
  width: 14%;
`;

export const FileNameCell = styled(StyledCell)`
  width: 24%;
`;

export const RequestCell = styled(StyledCell)`
  width: 13%;
`;

export const ResponseCell = styled(StyledCell)`
  width: 13%;
`;

export const TableFooter = styled.div`
  background: #f9f9f9;
  width: 100%;
  height: 64px;
`;

export const CloverIcon = styled(Icon)`
  padding-left: 10px;
  padding-right: 10px;
`;

export const DownloadText = styled.span`
  color: #0091ea;
  padding-left: 4px;
  cursor: pointer;
`;
