import React, { useEffect, useState } from "react";
import { Spinner } from "shamrock-clover-ui/dist/clover/components/Spinner/Spinner";
import moment, { Moment } from "moment";

import {
  StyledHeader,
  StyledTextInput,
  StyledMenu,
  Container,
  GridContainer,
  GridItem,
  PageNavFlex,
  SpinnerContainer,
  DatePickerStyled,
  StyledTable,
  TableHead,
  TableBody,
  StyledRow,
  CallTypeCell,
  ClientCodeCell,
  UsernameCell,
  DateCell,
  FileNameCell,
  RequestCell,
  ResponseCell,
  TableFooter,
  CloverIcon,
  DownloadText,
  StyledGrid,
} from "./APICallLogStyles";

import { getLogQueryAutofill, getLogs } from "./GetLogHttp";
import {
  APICallLogRecord,
  FormLogState,
  InputSelectedState,
  QueryInputSelected,
} from "./apiCallLogTypes";
import { MenuItem } from "shamrock-clover-ui/dist/clover/components/MenuItem/MenuItem";
import { APICallLogApiResult } from "./apiCallLogTypes";
import convert from "xml-js";

const APICallLogs = () => {
  const [formLogState, setFormLogState] = useState<FormLogState>({
    mgUsername: "",
    clientCode: "",
    type: "",
    requestDate: "",
    page: 1,
  });

  const [querySelected, setQuerySelected] = useState<QueryInputSelected>({
    mgUsername: "",
    type: "",
    clientCode: "",
    requestDate: "",
    page: 1,
  });

  const [inputSelectedState, setInputSelectedState] =
    useState<InputSelectedState>({
      mgUsername: [],
      clientCode: [],
      type: [],
      requestDate: "",
    });

  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  const [logData, setLogData] = useState<APICallLogApiResult>();

  const getLogData = async (query: QueryInputSelected) => {
    toggleIsLoading(true);
    try {
      const _getLogData = await getLogs(query);
      setLogData(_getLogData);
    } catch (e) {
      console.log("error :>> ", e);
    }
    toggleIsLoading(false);
  };

  useEffect(() => {
    getLogData(querySelected);
  }, [querySelected]);

  const handleOnChange = async (
    name: string,
    value: string,
    requestDate?: string
  ) => {
    setFormLogState({ ...formLogState, [name]: value });
    if (value.length > 2) {
      const data: any = await getLogQueryAutofill([name, value]);
      if (data.error) return data.error;
      setInputSelectedState({ ...inputSelectedState, [name]: data });
    }
    if (requestDate) {
      setFormLogState({ ...formLogState, [name]: requestDate });
      setQuerySelected({ ...formLogState, [name]: requestDate });
    }
  };

  const handleOnClick = async (name: string, value: string) => {
    setFormLogState({ ...formLogState, [name]: value });
    setInputSelectedState({ ...inputSelectedState, [name]: [] });
    setQuerySelected({ ...formLogState, [name]: value });
  };

  const handlePageClick = async (pageNumber: number) => {
    setFormLogState({ ...formLogState, page: pageNumber });
    setQuerySelected({ ...formLogState, page: pageNumber });
  };

  const { type, clientCode, mgUsername } = inputSelectedState;

  const handleDownloadClick = async (fileName: string, content: any) => {
    // If content is not a xml string, its the old way we used to send xml
    // so convert it like the old way until old logs are deleted
    if (typeof content !== "string") {
      content = convert.js2xml(content, { compact: true });
    }

    const xmlData = new Blob([content], { type: "text/xml" });
    var element = document.createElement("a");
    element.setAttribute("href", window.URL.createObjectURL(xmlData));
    element.setAttribute("download", `${fileName}.xml`);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const renderTable = () => {
    return logData ? (
      logData.data.map((log) => {
        var date = new Date(log.requestDate);
        const parser = new DOMParser();
        const xmlData = parser.parseFromString(
          log.request as unknown as string,
          "text/xml"
        );
        const tagName = log.type === "ImportWeb" ? "FileName" : "request-id";
        const fileNameElement = xmlData.getElementsByTagName(tagName)[0];
        return (
          <StyledRow>
            <CallTypeCell>{log.type}</CallTypeCell>
            <ClientCodeCell>{log.clientCode}</ClientCodeCell>
            <UsernameCell>{log.mgUsername}</UsernameCell>
            <DateCell>
              {date.toLocaleTimeString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </DateCell>
            <FileNameCell>{fileNameElement?.textContent}</FileNameCell>
            <RequestCell>
              <DownloadText
                onClick={() => {
                  handleDownloadClick(
                    "Request_".concat(log.requestDate),
                    log.request
                  );
                }}
              >
                Download
              </DownloadText>
            </RequestCell>
            <ResponseCell>
              <DownloadText
                onClick={() => {
                  handleDownloadClick(
                    "Response_".concat(log.requestDate),
                    log.response
                  );
                }}
              >
                Download
              </DownloadText>
            </ResponseCell>
          </StyledRow>
        );
      })
    ) : (
      <></>
    );
  };

  const renderPageNumber = (logData: {
    data: APICallLogRecord[];
    limit: any;
    page: any;
    totalPages: number;
  }) => {
    return logData.page === logData.totalPages ? (
      <p>
        {(logData.page * logData.limit - logData.limit + 1).toLocaleString()}-
        {(
          logData.page * logData.limit -
          logData.limit +
          logData.data.length
        ).toLocaleString()}{" "}
        of{" "}
        {(
          logData.totalPages * logData.limit -
          logData.limit +
          logData.data.length
        ).toLocaleString()}
      </p>
    ) : (
      <p>
        {(logData.page * logData.limit - logData.limit + 1).toLocaleString()}-
        {(logData.page * logData.limit).toLocaleString()} of{" "}
        {(logData.totalPages * logData.limit).toLocaleString()}
      </p>
    );
  };
  return (
    <Container>
      <GridContainer container>
        <GridItem item sm={12}>
          <StyledHeader>API Call Log</StyledHeader>
        </GridItem>
        <GridItem item sm={3}>
          <StyledTextInput
            label="Call type"
            value={formLogState.type}
            variant="filled"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChange("type", value);
            }}
          />
          <StyledMenu>
            {type?.length !== 0
              ? type?.map((x) => (
                  <MenuItem onClick={() => handleOnClick("type", x)} key={x}>
                    {x}
                  </MenuItem>
                ))
              : null}
          </StyledMenu>
        </GridItem>
        <GridItem item sm={3}>
          <StyledTextInput
            label="Client Code"
            value={formLogState.clientCode}
            variant="filled"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChange("clientCode", value);
            }}
          />
          <StyledMenu>
            {clientCode?.length !== 0
              ? clientCode?.map((x) => (
                  <MenuItem
                    onClick={() => handleOnClick("clientCode", x)}
                    key={x}
                  >
                    {x}
                  </MenuItem>
                ))
              : null}
          </StyledMenu>
        </GridItem>
        <GridItem item sm={3}>
          <StyledTextInput
            label="Username"
            value={formLogState.mgUsername}
            variant="filled"
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChange("mgUsername", value);
            }}
          />

          <StyledMenu>
            {mgUsername?.length !== 0
              ? mgUsername?.map((x) => (
                  <MenuItem
                    onClick={() => handleOnClick("mgUsername", x)}
                    key={x}
                  >
                    {x}
                  </MenuItem>
                ))
              : null}
          </StyledMenu>
        </GridItem>
        <GridItem item sm={3}>
          <DatePickerStyled
            format="MM/DD/YYYY"
            onChange={(date: Moment) => {
              handleOnChange("requestDate", "", date.format("YYYY-MM-DD"));
            }}
            maxDate={moment().add(1, "hours")}
            placeholder="Date"
            value={
              formLogState.requestDate ? moment(formLogState.requestDate) : null
            }
            readOnly
          />
        </GridItem>
        <GridItem item sm={12}>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              <StyledGrid container item sm={12}>
                <StyledTable>
                  <TableHead>
                    <StyledRow>
                      <CallTypeCell>CALL TYPE</CallTypeCell>
                      <ClientCodeCell>CLIENT CODE</ClientCodeCell>
                      <UsernameCell>USERNAME</UsernameCell>
                      <DateCell>DATE & TIME</DateCell>
                      <FileNameCell>FILE NAME</FileNameCell>
                      <RequestCell>REQUEST DOWNLOAD</RequestCell>
                      <ResponseCell>RESPONSE DOWNLOAD</ResponseCell>
                    </StyledRow>
                  </TableHead>
                  <TableBody>{renderTable()}</TableBody>
                </StyledTable>
              </StyledGrid>
              <TableFooter>
                {logData ? (
                  <PageNavFlex>
                    {renderPageNumber(logData)}
                    <CloverIcon
                      icon="chevronLeft"
                      size="12"
                      onClick={() => {
                        console.log("left");
                        handlePageClick(logData?.page - 1);
                      }}
                    ></CloverIcon>
                    <CloverIcon
                      icon="chevronRight"
                      size="12"
                      onClick={() => {
                        console.log("right");
                        handlePageClick(logData?.page + 1);
                      }}
                    ></CloverIcon>
                  </PageNavFlex>
                ) : (
                  <></>
                )}
              </TableFooter>
            </>
          )}
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default APICallLogs;
