import * as React from "react";
import { Spinner } from "shamrock-clover-ui/dist/clover/components/Spinner/Spinner";

interface Props {
  size?: string;
  height?: string;
  width?: string;
  useWait?: boolean;
}

const SpinnerWithWait: React.FunctionComponent<Props> = ({
  size,
  height,
  width,
  useWait = true,
}) => {
  const [showSpinner, setShowSpinner] = React.useState(false);

  React.useEffect(() => {
    if (useWait) {
      const timeout = setTimeout(() => {
        setShowSpinner(true);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
    setShowSpinner(true);
  }, [setShowSpinner, useWait]);

  return showSpinner ? (
    <div>
      <Spinner size={size}></Spinner>
    </div>
  ) : null;
};

export default SpinnerWithWait;
