import { TextInput } from "shamrock-clover-ui/dist/clover/components/TextInput/TextInput";
import { Button } from "shamrock-clover-ui/dist/clover/components/Button/Button";
import { Card } from "shamrock-clover-ui/dist/clover/components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { styled } from "shamrock-clover-ui/dist/clover/Theme/Theme";

export const StyledHeader = styled.p`
  font-size: 36px;
  margin-bottom: 0;
`;

export const StyledText = styled.p`
  font-size: 18px;
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: 1em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 1em;
  float: right;
`;

export const GridItem = styled(Grid)`
  width: inherit;
`;

export const TokenCard = styled(Card)`
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  width: auto;
  height: auto;
  padding: 26px;
  display: block;
  width: -webkit-fill-available;
  margin: 30px;
`;

export const SuggestedEmailText = styled.span`
  color: #0091ea;
  padding-left: 4px;
  cursor: pointer;
`;

export const Flex = styled.div`
  display: flex;
`;

export const CopyTokenFlex = styled(Flex)`
  align-items: center;
  padding: 15px;
  float: right;
  margin-bottom: 30px;
`;

export const CopyTokenText = styled.div`
  margin-top: 2.5px;
  margin-left: 5px;
  text-align: center;
  color: #0091ea;
  font-size: 14px;
  cursor: pointer;
`;

export const StyledInputGrid = styled(Grid)`
  @media (max-width: 600px) {
    height: auto;
  }
  background-color: white;
  padding: 30px;
  height: 95vh;
  align-content: flex-start;
`;

export const StyledTokenGrid = styled(Grid)`
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;

export const HelperText = styled.div`
  font-family: proxima-nova, sans-serif;
  margin: 5px 0px 0px 10px;
  font-size: 0.75em;
  color: #666666;
  background-color: transparent;
  margin-bottom: 1em;
`;

export const StyledHr = styled.hr`
  width: calc(100% + 58px);
  margin-left: -30px;
  border-top: 1px solid #cccccc;
`;
