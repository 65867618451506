import { get } from "../../utils/httpUtils";

export const getTokenByUsername = async (mgUsername: string) => {
  const { data } = await get<{
    data: {
      json():
        | { token: string; clientCode: string }
        | PromiseLike<{ token: string; clientCode: string }>;
      token: string;
      clientCode: string;
    };
  }>(`mgToken/${mgUsername}`)();
  console.log(data);
  return data;
};
