import { post } from "../../utils/httpUtils";
import {
  MGTokenBody,
  MGEmailTokenBody,
  MGTokenApiResult,
  MGDeleteApiResult,
} from "./mgTokenTypes";

export const insertMercuryGateToken = async (body: MGTokenBody) => {
  return body
    ? post<MGTokenApiResult, MGTokenBody>(`mgToken`)(body)
    : Promise.reject(new Error("Invalid request body"));
};

export const emailMercuryGateToken = async (body: MGEmailTokenBody) => {
  return body
    ? post<MGTokenApiResult, MGEmailTokenBody>(
        `mgToken/sendMercuryGateTokenEmail`
      )(body)
    : Promise.reject(new Error("Invalid request body"));
};

export const deleteMercuryGateUser = async (mgUsername: string) => {
  return mgUsername
    ? post<MGDeleteApiResult, {}>(
        `mgToken/mercuryGateDeleteUser/${mgUsername}`
      )({})
    : Promise.reject(new Error("Invalid request body"));
};
