import { logFirebaseUser } from "./firebaseUtils";

const USER_URL = process.env.REACT_APP_USER_URL || "";
const MERCURYGATE_TOKEN_URL = process.env.REACT_APP_MERCURYGATE_TOKEN_URL || "";
const RTSPRO_URL = process.env.REACT_APP_RTS_PRO_SHELL_URL || "";
const TOKEN = "TOKEN";
const USER = "USER";
const MERCURY_GATE_PERMISSION = "ryantrans.MercuryGateToken";

export interface UserInfo {
  token: string;
  user: string;
  id: string;
}

export interface CurrentUser {
  company: string;
  createdOn: string;
  crmId: string;
  deviceIds: string[];
  email: string;
  factoring_status: string;
  firstname?: string;
  lastname: string;
  note: string;
  permissions?: { name: string; status: string }[];
  pin: string;
  roles: string[];
  status: string;
  user_type: string;
  _id: string;
  password: string;
  phone?: string;
  mcleod_customer_id: string[];
  bridge_id: string[];
  device_ids: string[];
  mc_number?: string;
}

export const getUserHttp = async (token: string, userName: string) => {
  const response = await fetch(`${USER_URL}user/user/` + userName, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()) as CurrentUser;
};

const setUser = (user: CurrentUser) => {
  localStorage.setItem(USER, JSON.stringify(user));
};

export const getUserLocalStorage = () => {
  const user = localStorage.getItem(USER);
  return user ? (JSON.parse(user) as CurrentUser) : null;
};

export const getAuthToken = () => {
  console.log("getAuthToken(): " + localStorage.getItem(TOKEN));
  return localStorage.getItem(TOKEN);
};

const setToken = (token: string) => {
  console.log("setToken( " + token + " )");
  localStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  console.log("getToken(): " + localStorage.getItem(TOKEN));
  return localStorage.getItem(TOKEN);
};

export const getUserEmail = () => {
  let user = getUserLocalStorage();
  return user && user.email ? user.email : "";
};

export const authenticateAndSaveUser = async (
  token: string,
  userName: string
) => {
  setToken(token);

  const user = await getUserHttp(token, userName);

  logFirebaseUser(user._id, user.user_type);

  setUser(user as any);
};

export const isAuthenticated = () => {
  const user = getUserLocalStorage();
  const token = getToken();

  if (user && token) {
    logFirebaseUser(user._id, user.user_type);

    return true;
  }
  return false;
};

export const redirectToLogin = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER);

  const url = `${RTSPRO_URL}/auth?finaldestination=${encodeURIComponent(
    MERCURYGATE_TOKEN_URL
  )}`;
  window.location.replace(url);
};

export const redirectToLogout = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER);

  const redirect = encodeURIComponent(MERCURYGATE_TOKEN_URL);
  window.location.assign(`${RTSPRO_URL}/logout?finaldestination=${redirect}`);
};

export const isMercuryGateUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === MERCURY_GATE_PERMISSION)
  );
};
