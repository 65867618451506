import Service from "../Service";
import { TOKEN_CACHE, CURRENT_USER } from "../../utils/constants";
import LocalAuth from "@shamrock-core/common/authentication/local-authentication";

const MERCURYGATE_TOKEN_URL = process.env.REACT_APP_MERCURYGATE_TOKEN_URL || "";
const RTSPRO_URL = process.env.REACT_APP_RTS_PRO_SHELL_URL || "";

export interface Token {
  token: string;
  user: string;
}

class AuthService extends Service {
  constructor() {
    super("");
    // super(Config.sessionServiceUrl);
  }

  async checkSession() {
    LocalAuth.checkSession();
  }

  redirectToLogin() {
    this.deleteToken();
    this.deleteUser();
    const origin = encodeURIComponent(MERCURYGATE_TOKEN_URL);
    LocalAuth.redirectToLogin(origin);
  }

  logoutRedirect(): void {
    this.deleteToken();
    this.deleteUser();
    const origin = encodeURIComponent(MERCURYGATE_TOKEN_URL);
    LocalAuth.redirectToLogout(origin);
  }

  deleteToken() {
    localStorage.removeItem(TOKEN_CACHE);
  }

  deleteUser() {
    localStorage.removeItem(CURRENT_USER);
  }

  setToken(token: Token) {
    localStorage.setItem(TOKEN_CACHE, JSON.stringify(token));
  }

  get incomingSearch() {
    return LocalAuth.incomingSearch;
  }

  get isAuthed() {
    return LocalAuth.isAuthed();
  }

  get getToken() {
    return LocalAuth.getTokenValue();
  }

  static getLoginUrl() {
    const origin = encodeURIComponent(MERCURYGATE_TOKEN_URL);
    return `${RTSPRO_URL}/auth?finaldestination=${origin}`;
  }
}

export default AuthService;
